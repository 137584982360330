h1 {
  color: #ff69b4;
  font-family: cursive;
}

button {
  display: block;
}

/*# sourceMappingURL=index.fa8ab3f6.css.map */
